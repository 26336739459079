<template>
  <v-data-table :headers="headers" :items="items.existing" :value="value.existing" @input="onInput">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ label }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog persistent v-model="participantsToAddDialog" v-if="!disabled" width="auto">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  class="ml-2"
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span
                >{{ $i18n.translate("New") }} {{ $i18n.translate("Promotion") }}
                {{ $i18n.translate("Participant") }}</span
              >
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Participants</span>
              <v-spacer> </v-spacer>
              <v-form @submit.stop.prevent="onParticipantsToAddFilter">
                <v-text-field
                  class="mr-4"
                  v-model="participantsToAdd.search"
                  :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Participants')"
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  @click:clear="onParticipantsToAddClearFilter"
                ></v-text-field>
              </v-form>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $i18n.translate("Advanced Search") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
                    <v-list-item selectable>
                      <v-list-item-content>
                        <ParticipantTypesField
                          dense
                          v-model="participantsToAdd.filterByParticipantTypes"
                          label="Participant Types"
                          @input="getParticipantsToAddData"
                        ></ParticipantTypesField>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-tooltip bottom v-if="hasFilters">
                <template v-slot:activator="{ on: onTooltip }">
                  <v-btn icon v-on="{ ...onTooltip }" @click="onParticipantsToAddClearSearch">
                    <v-icon>mdi-magnify-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Clear Search") }}</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <v-data-table
                v-model="participantsToAdd.selected"
                show-select
                :items="participantsToAdd.items"
                :headers="participantsToAdd.headers"
                :server-items-length="participantsToAdd.total"
                :options.sync="participantsToAddOptions"
                :loading="participantsToAdd.loading"
              >
                <template v-slot:item.organization="{ item }">
                  <span v-if="item.organization">
                    {{ item.organization.name }}
                  </span>
                </template>
                <template v-slot:item.updatedDate="{ item }">
                  {{ item.updatedDate | formatDateFromNow }}
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onParticipantsToAddClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onParticipantsToAddIncludeSubmit">Include</v-btn>
              <v-btn color="primary" @click="onParticipantsToAddExcludeSubmit">Exclude</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="participantEditDialog" v-if="!disabled" width="500">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Edit {{ $i18n.translate("Participant") }} -
                {{ editedItem && editedItem.participant ? editedItem.participant.fullName : "" }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-form :value="valid" @submit.prevent="onSave" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-switch label="Include in Promotion?" v-model="editedItem.includeInPromotion"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onSave" :disabled="!valid">{{ $i18n.translate("Save") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.includeInPromotion="{ item }">
      <v-icon v-if="item.includeInPromotion" color="green">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template>
    <template v-slot:item.participant.organization="{ item }">
      <span v-if="item.participant.organization">
        {{ item.participant.organization.name }}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" v-if="!disabled" @click="onEditItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon v-if="!disabled" @click="onDeleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "../../services/api.service";
import ParticipantTypesField from "../fields/ParticipantTypesField";

export default {
  name: "PromotionParticipantsTable",
  props: {
    promotionId: Number,
    value: Object,
    disabled: Boolean,
    isCopy: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "Include / Exclude Specific Participants"
    }
  },
  components: {
    ParticipantTypesField
  },
  data: () => ({
    valid: false,
    loaded: false,
    participantEditDialog: false,
    participantsToAddDialog: false,
    headers: [
      {
        text: "Include / Exclude",
        value: "includeInPromotion",
        align: "center"
      },
      { text: "Key", value: "participant.participantKey" },
      { text: "First Name", value: "participant.user.firstName" },
      { text: "Last Name", value: "participant.user.lastName" },
      {
        value: "participant.organization",
        text: "Company"
      },
      {
        value: "participant.participantType.name",
        text: "Participant Type",
        align: "end"
      },

      { text: "Actions", value: "actions", sortable: false }
    ],
    rules: {
      payoutType: [v => !!v || "Payout Type is required"],
      payoutAmount: [
        v => !!v || "Payout Amount is required",
        v => (!!v && v < 49999) || "Payout Amount must be lower than 49999"
      ]
    },
    items: {
      existing: [],
      deleted: []
    },
    editedIndex: -1,
    editedItem: {},
    editedPromotionParticipantPayouts: [],
    defaultItem: {},

    participantsToAdd: {
      search: "",
      filterByParticipantTypes: [],
      loading: false,
      selected: [],
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "participantKey",
          text: "Key",
          sortable: true
        },
        {
          value: "user.firstName",
          text: "First Name",
          sortable: true
        },
        {
          value: "user.lastName",
          text: "Last Name",
          sortable: true
        },
        {
          value: "email1",
          text: "Email",
          sortable: true
        },
        {
          value: "organization.organizationKey",
          text: "Company Number",
          sortable: true
        },
        {
          value: "organization",
          text: "Company",
          sortable: true
        },
        {
          value: "participantType.name",
          text: "Participant Type",
          align: "end",
          sortable: true
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        }
      ],
      total: 0
    },
    participantsToAddOptions: {
      itemsPerPage: 10
    }
  }),
  watch: {
    participantEditDialog(val) {
      val || this.onClose();
    },
    participantsToAddOptions: {
      handler() {
        this.getParticipantsToAddData();
      },
      deep: true
    },
    promotionId: {
      immediate: true,
      handler() {
        this.initialize();
      }
    },
    "$route.params.id": {
      immediate: true,
      deep: true,
      handler() {
        this.initialize();
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.promotionId > 0) {
        ApiService.post("/api/promotionParticipants/promotion/" + this.promotionId + "/search?size=500", {}).then(
          ({ data }) => {
            this.items.existing = data.content;
            if (this.isCopy) {
              data.content.forEach(elem => (elem.id = undefined));
            }
            this.items.deleted = [];
            this.$emit("input", this.items);
            this.loaded = true;
          }
        );
      }
    },
    onInput() {
      this.$emit("input", this.items);
    },

    onEditItem(item) {
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.participantEditDialog = true;
    },

    onDeleteItem(item) {
      const index = this.items.existing.indexOf(item);
      let deletedItems = this.items.existing.splice(index, 1);
      deletedItems = deletedItems.reduce((acc, cur) => {
        if (cur.id) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onClose() {
      this.participantEditDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onParticipantsToAddFilter() {
      this.participantsToAddOptions.page = 0;
      this.getParticipantsToAddData();
    },

    onParticipantsToAddClearFilter() {
      this.participantsToAdd.search = "";
      this.getParticipantsToAddData();
    },
    onParticipantsToAddClearSearch() {
      this.participantsToAdd.search = "";
      this.participantsToAdd.filterByParticipantTypes = [];
      this.getParticipantsToAddData();
    },

    onParticipantsToAddIncludeSubmit() {
      let promotionParticipants = this.items.existing.slice();
      this.participantsToAdd.selected.some(participant => {
        let found = promotionParticipants.findIndex(needle => needle.participant.id == participant.id);
        if (found == -1) {
          promotionParticipants = promotionParticipants.concat([
            { dirty: true, includeInPromotion: true, participant: participant }
          ]);
        }
      });
      this.items.existing = promotionParticipants;
      this.participantsToAddDialog = false;
      this.onParticipantsToAddClearFilter();
      this.onParticipantsToAddClearSearch();
      this.participantsToAdd.selected = [];
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onParticipantsToAddExcludeSubmit() {
      let promotionParticipants = this.items.existing.slice();
      this.participantsToAdd.selected.some(participant => {
        let found = promotionParticipants.findIndex(needle => needle.participant.id == participant.id);
        if (found == -1) {
          promotionParticipants = promotionParticipants.concat([
            {
              dirty: true,
              includeInPromotion: false,
              participant: participant
            }
          ]);
        }
      });
      this.items.existing = promotionParticipants;
      this.participantsToAddDialog = false;
      this.onParticipantsToAddClearFilter();
      this.onParticipantsToAddClearSearch();
      this.participantsToAdd.selected = [];
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onParticipantsToAddClose() {
      this.participantsToAddDialog = false;
      this.onParticipantsToAddClearFilter();
      this.onParticipantsToAddClearSearch();
      this.participantsToAdd.selected = [];
      this.onParticipantsToAddClearFilter();
    },

    onSave() {
      this.editedItem.dirty = true;
      this.editedItem.promotionParticipantPayouts = this.editedPromotionParticipantPayouts;
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      } else {
        this.items.existing.push(this.editedItem);
      }
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
      this.onClose();
    },

    getParticipantsToAddData() {
      this.participantsToAdd.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.participantsToAddOptions;

      let filters = {};
      if (this.participantsToAdd.search && this.participantsToAdd.search.length > 0) {
        filters.keyword = this.participantsToAdd.search;
      }

      if (
        this.participantsToAdd.filterByParticipantTypes &&
        this.participantsToAdd.filterByParticipantTypes.length > 0
      ) {
        filters.participantTypes = this.participantsToAdd.filterByParticipantTypes.map(participantType => {
          return {
            id: participantType.id
          };
        });
      }
      ApiService.post(
        "/api/participants/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        filters
      )
        .then(({ data }) => {
          this.participantsToAdd.loading = false;
          this.participantsToAdd.items = data.content;
          this.participantsToAdd.total = data.totalElements;
        })
        .catch(() => {
          this.participantsToAdd.loading = false;
        });
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Promotion Participant" : "Edit Promotion Participant Field";
    },
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        (this.participantsToAdd.filterByParticipantTypes && this.participantsToAdd.filterByParticipantTypes.length > 0);
      return check;
    }
  }
};
</script>
